import { Component, OnInit } from '@angular/core';
import { SLetterService } from './s-letter.service';

@Component({
  selector: 'app-print-letter',
  templateUrl: './print-letter.component.html',
  styleUrls: ['./print-letter.component.scss']
})
export class PrintLetterComponent implements OnInit {

  outward_no = ''
  outward_date 
  constructor(public objSLetterService: SLetterService) { }

  ngOnInit(): void {
    this.outward_no = localStorage.getItem('outward_no')
    this.outward_date = localStorage.getItem('outward_date')
    this.objSLetterService.getLetterData()
  }

}
