import { Injectable } from '@angular/core';
import { mAcademicSession, mCenter, mCenterList, mCopyCaseEntry, mCopyCaseEntryAssignIV, mPunishment } from './m-copy-case-entry';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SCopyCaseEntryService {

  pCopyCaseEntry: mCopyCaseEntry = new mCopyCaseEntry()
  pCopyCaseEntryReport: mCopyCaseEntry = new mCopyCaseEntry()
  arrCopyCaseEntry: mCopyCaseEntry[] = []
  arrCopyCaseEntry2: mCopyCaseEntry[] = []

  arrCopyCaseEntrytrial: mCopyCaseEntry[] = []
  arrCopyCaseEntry3: mCopyCaseEntry[] = []
  arrCopyCaseEntry4: mCopyCaseEntry[] = []
  arrCopyCaseEntry5: mCopyCaseEntry[] = []

  arrmCopyCaseEntryAssignIV: mCopyCaseEntryAssignIV[] = []
  arrCenter: mCenter[] = []
  arrmCenterList: mCenterList[] = []

  arrAcademicSession: mAcademicSession[] = []
  arrPunishment: mPunishment[] = []

  constructor(private http: HttpClient) { }

  getMaster() {
    this.http.get(environment.apiKey + 'college_master').subscribe(
      (res: any) => {
        this.arrCenter = res

        this.http.get(environment.apiKey + 'academic_session').subscribe(
          (res: any) => {
            this.arrAcademicSession = res
          }
        )
      }
    )
  }

  getPunishment() {
    this.http.get(environment.apiKey + 'copy_case_punishment').subscribe(
      (res: any) => {
        this.arrPunishment = res

        this.http.get(environment.apiKey + 'academic_session').subscribe(
          (res: any) => {
            this.arrAcademicSession = res
          }
        )
      })
  }

  annex_v_a_count: number = 0
  annex_v_b_count: number = 0
  annex_v_c_count: number = 0

  getCopyCasesAnnexureV_a() {
    this.arrCopyCaseEntry3 = []
    this.arrCopyCaseEntry4 = []
    this.arrCopyCaseEntry5 = []


    this.http.get(environment.apiKey + 'copy_case_entry').subscribe(
      (res: any) => {
        this.arrCopyCaseEntrytrial = res
        this.arrCopyCaseEntry3 = this.arrCopyCaseEntrytrial.filter(s=>s.annex_III_cat == s.annex_II_cat && s.annex_III_cat != 40)
        this.arrCopyCaseEntry4 = this.arrCopyCaseEntrytrial.filter(s=>s.annex_III_cat != s.annex_II_cat && s.annex_III_cat != 40)
        this.arrCopyCaseEntry5 = this.arrCopyCaseEntrytrial.filter(s=>s.annex_IV_cat == 40)

        this.annex_v_a_count = this.arrCopyCaseEntry3.length 
        this.annex_v_b_count = this.arrCopyCaseEntry4.length
        this.annex_v_c_count = this.arrCopyCaseEntry5.length
      })
  }

  getCopyCases() {
    this.arrCopyCaseEntry = []
    this.http.get(environment.apiKey + 'copy_case_entry').subscribe(
      (res: any) => {
        this.arrCopyCaseEntry = res

        var count = 1
        var case_no = 1
        this.arrCopyCaseEntry.forEach(element => {
          element.sr_no_new = count
          element.case_no = case_no
          console.log(this.arrCopyCaseEntry)
          count++
          case_no++
        });

        this.arrCopyCaseEntry.forEach(element => {
          if((element.annex_II_cat == element.annex_III_cat) || (element.annex_II_cat == element.annex_IV_cat)){
            if(element.annex_II_cat == 40){
              element.annex_final = -1
            }
            else {
              element.annex_final = element.annex_II_cat
            }
          }
          else if((element.annex_II_cat == element.annex_III_cat) || (element.annex_III_cat == element.annex_IV_cat)){
            if(element.annex_III_cat == 40){
              element.annex_final = -1
            }
            else {
              element.annex_final = element.annex_III_cat
            }
          }
        });
      }
    )
  }

  getCopyCasesById(app_id) {
    this.arrCopyCaseEntry = []
    this.http.get(environment.apiKey + 'copy_case_entry/' + app_id).subscribe(
      (res: any) => {
        this.pCopyCaseEntry = res
        this.http.get(environment.apiKey + 'copy_case_entry/GetStudentDetails?roll_no=' + this.pCopyCaseEntry.student_seat_no).subscribe(
          (res: any) => {
            var count = 0
            res.exam.forEach(element => {
              this.arrmStudentCenterList.push(new mStudentCenterList(count, element.center_code, this.arrCenter.filter(s => s.college_code == element.center_code)[0].college_name, element.exam))
              count++
            });
            this.arrmStudentSubject = res.subject
          })
      }
    )
  }

  arrmAnnexure_pages: mAnnexure_pages[] = []
  getCopyCasesII() {
    this.arrCopyCaseEntry = []
    this.http.get(environment.apiKey + 'copy_case_entry/Getcopy_case_entry_by_statusII').subscribe(
      (res: any) => {
        this.arrCopyCaseEntry = res

        var page_no = 1
        var count = 1
        this.arrCopyCaseEntry.forEach(element => {
          if (count == 1) {
            this.arrmAnnexure_pages.push(new mAnnexure_pages(page_no))
          }
          if (count == 20) {
            element.page_no = page_no
            page_no++
            count = 1
          }
          else {
            element.page_no = page_no
            count++
          }
        });

        var count_1 = 1
        var case_no = 1
        this.arrCopyCaseEntry.forEach(element => {
          element.sr_no_new = count_1
          element.case_no = case_no
          console.log(this.arrCopyCaseEntry)
          count_1++
          case_no++
        });

        console.log(this.arrmAnnexure_pages)
        console.log(this.arrCopyCaseEntry)
      }
    )
  }

  getCopyCasesIII() {
    this.arrCopyCaseEntry = []
    this.http.get(environment.apiKey + 'copy_case_entry/Getcopy_case_entry_by_statusIII').subscribe(
      (res: any) => {
        this.arrCopyCaseEntry = res

        var page_no = 1
        var count = 1
        this.arrCopyCaseEntry.forEach(element => {
          if (count == 1) {
            this.arrmAnnexure_pages.push(new mAnnexure_pages(page_no))
          }
          if (count == 20) {
            element.page_no = page_no
            page_no++
            count = 1
          }
          else {
            element.page_no = page_no
            count++
          }
        });

        var count_1 = 1
        var case_no = 1
        this.arrCopyCaseEntry.forEach(element => {
          element.sr_no_new = count_1
          element.case_no = case_no
          console.log(this.arrCopyCaseEntry)
          count_1++
          case_no++
        });

        console.log(this.arrmAnnexure_pages)
        console.log(this.arrCopyCaseEntry)
      }
    )
  }

  getCopyCasesIV() {
    this.arrCopyCaseEntry = []
    this.http.get(environment.apiKey + 'copy_case_entry/Getcopy_case_entry_by_statusIV').subscribe(
      (res: any) => {
        this.arrCopyCaseEntry = res

        // this.arrCopyCaseEntry.forEach(element => {
        //   console.log(element.annex_III_assign)
        //   element.annex_IV_assign = element.annex_III_assign
        // });
      }
    )
  }

  getCopyCasesV() {
    this.arrCopyCaseEntry = []
    this.http.get(environment.apiKey + 'copy_case_entry/Getcopy_case_entry_by_statusV').subscribe(
      (res: any) => {
        this.arrCopyCaseEntry = res

        this.arrCopyCaseEntry.forEach(element => {
          if((element.annex_II_cat == element.annex_III_cat) || (element.annex_II_cat == element.annex_IV_cat)){
            if(element.annex_II_cat == 40){
              element.annex_final = -1
            }
            else {
              element.annex_final = element.annex_II_cat
            }
          }
          else if((element.annex_II_cat == element.annex_III_cat) || (element.annex_III_cat == element.annex_IV_cat)){
            if(element.annex_III_cat == 40){
              element.annex_final = -1
            }
            else {
              element.annex_final = element.annex_III_cat
            }
          }
        });
      }
    )
  }

  getCopyCasesVi() {
    this.arrCopyCaseEntry = []
    this.http.get(environment.apiKey + 'copy_case_entry/Getcopy_case_entry_by_statusVi').subscribe(
      (res: any) => {
        this.arrCopyCaseEntry = res
        console.log(this.arrCopyCaseEntry)
      }
    )
  }

  getCopyCasesIVAssign() {
    this.arrmCopyCaseEntryAssignIV = []
    this.http.get(environment.apiKey + 'copy_case_entry/GetIVAssignments').subscribe(
      (res: any) => {
        this.arrmCopyCaseEntryAssignIV = res
      }
    )
  }

  getCopyCasesIVAssignReport2() {
    this.arrmCopyCaseEntryAssignIV = []
    this.http.get(environment.apiKey + 'copy_case_entry/GetIVAssignmentsReport2').subscribe(
      (res: any) => {
        this.arrCopyCaseEntry2 = res
        var half_row = Math.round(this.arrCopyCaseEntry2.length / 2)

        var count = 1
        var sr_no_annex_4 = 1
        this.arrCopyCaseEntry2.forEach(element => {
          if (count == half_row) {
            sr_no_annex_4++
          }
          element.sr_id_annex_4 = sr_no_annex_4
          count++
        });
      }
    )
  }

  getCopyCaseBYAppId(app_id) {
    this.http.get(environment.apiKey + 'copy_case_entry/' + app_id).subscribe(
      (res: any) => {
        this.pCopyCaseEntryReport = res
      }
    )
  }

  getCopyCasesCenterList() {
    this.http.get(environment.apiKey + 'copy_case_entry/GetCentersOfcopy_case_entry').subscribe(
      (res: any) => {
        this.arrmCenterList = res
        // this.getCopyCases()
      }
    )
  }

  // copy_case_entry/GetCentersOfcopy_case_entry

  submitCopyCase() {
    this.http.post(environment.apiKey + 'copy_case_entry', this.pCopyCaseEntry).subscribe(
      (res: any) => {
        alert('Entry saved successfully !')
        this.pCopyCaseEntry = new mCopyCaseEntry()
      }
    )
  }

  updateCopyCase(app_id) {
    this.http.put(environment.apiKey + 'copy_case_entry/' + app_id, this.pCopyCaseEntry).subscribe(
      (res: any) => {
        alert('Entry updated successfully !')
      }
    )
  }

  arrmStudentSubject: any = []
  arrmStudentCenterList: mStudentCenterList[] = []

  getStudentByRollNo(roll_no) {
    this.http.get(environment.apiKey + 'copy_case_entry/GetStudentDetails?roll_no=' + roll_no).subscribe(
      (res: any) => {
        console.log(res)
        this.pCopyCaseEntry.student_first_name = res.student_name
        this.pCopyCaseEntry.student_mobile = res.mobile
        this.pCopyCaseEntry.exam_name = res.exam
        // this.pCopyCaseEntry.email = res.email
        this.pCopyCaseEntry.college_code = res.college_code
        this.pCopyCaseEntry.center_code = 0
        this.pCopyCaseEntry.exam_name = ''
        this.pCopyCaseEntry.student_address = res.address_perm
        // this.pCopyCaseEntry = new mCopyCaseEntry()
        this.arrmStudentCenterList = []

        var count = 0
        res.exam.forEach(element => {
          this.arrmStudentCenterList.push(new mStudentCenterList(count, element.center_code, this.arrCenter.filter(s => s.college_code == element.center_code)[0].college_name, element.exam))
          count++
        });
        this.pCopyCaseEntry.center_code = 0
        this.arrmStudentSubject = res.subject
        console.log(this.arrmStudentSubject)

        // this.http.get(environment.apiKey + 'copy_case_entry/GetStudentExam?roll_no=' + roll_no).subscribe(
        //   (res: any) => {
        //     this.arrmStudentSubject = res
        //   })
      }
    )
  }

  updateCatII() {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json;charset=utf-8')
      .append('Authorization', 'Bearer');

    const body = JSON.stringify(this.arrCopyCaseEntry);
    this.http.post(environment.apiKey + 'copy_case_entry/Postcopy_case_entryCategory', body,
      { headers: headers }).subscribe(res => {
        alert('Category updated successfully !')
        this.getCopyCases()
      })
  }

  deleteEntry(app_id) {
    this.http.delete(environment.apiKey + 'copy_case_entry/' + app_id).subscribe(
      (res: any) => {
        alert('Deleted !')
        this.getCopyCasesCenterList()
        this.getCopyCases()
      })
  }
}


export class mStudentSubject {
  id: number = 0
  subject_name: string = ''
  subject_code: string = ''
  roll_no: string = ''
}

export class mStudentCenterList {
  constructor(
    public sr_no: number = 0,
    public college_code: number = 0,
    public college_name: string = '',
    public exam: string = ''
  ) { }
}

export class mAnnexure_pages {
  constructor(
    public page_no: number = 0
  ) { }
}